import {
    Button,
    Checkbox,
    GridTableBody as GridTableBodyE,
    GridTableCell as GridTableCellE,
    GridTable as GridTableE,
    GridTableRow as GridTableRowE,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
    PageDimmer,
    ValidationPopupInput,
} from "@ramble/ramble-ui";
import { DispatchProp, connect } from "react-redux";
import {DraperyModule, WorkOrderModule, WorkroomSettingModule} from "../redux/modules/index";
import React, { ChangeEvent, PureComponent } from "react";
import { TooltipPopup, TooltipText } from "../components/calculator/styles";
import styled, { css, theme } from "../theme";

import DraperyConfiguration3Panels from "../../src/assets/icons/configuration/panels-3.svg";
import DraperyConfiguration4Panels from "../../src/assets/icons/configuration/panels-4.svg";
import DraperyConfiguration5Panels from "../../src/assets/icons/configuration/panels-5.svg";
import DraperyConfigurationPair from "../../src/assets/icons/configuration/panels-pair.svg";
import DraperyConfigurationSingleCenterPanel from "../../src/assets/icons/configuration/panels_single.svg";
import DraperyConfigurationSingleLeftPanel from "../../src/assets/icons/configuration/panels-left.svg";
import DraperyConfigurationSingleRightPanel from "../../src/assets/icons/configuration/panels-right.svg";
import { RootState } from "../redux";
import SelectList from "../components/UI/select-list";
import { getWorkOrderRecommendations } from "../api/work-order";
import { AnyAction } from "redux";

const CancelButton = styled(Button)`
    margin-right: auto !important;
`;

const ModalHeaderRecommendationsOverrides = styled(ModalHeader)`
    color: #306f82;
    border-bottom: none;
    padding-bottom: 0;

    div {
        width: 100%;
        border-bottom: 10px solid #e6f1f4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.5rem;
    }

    .modal-title {
        font-size: 26px;
    }
`;

const ModalSpan = styled.span`
    color: ${theme.colors.primary};
`;

const TwoCounterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 6% 5% 0;
`;

const TwoCounterTitleBox = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: 600;
`;

const TwoCounterLabelValueBox = styled.div`
    display: flex;
    flex-direction: row;
`;

const TwoCounterLabel = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
`;

const TwoCounterValue = styled.div`
    color: ${theme.colors.primary};
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin: 10px 20px 0 0;
    padding: 0 5px;
    min-width: 25px;
`;

const PanelConfigurationWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const PanelConfigurationSubWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
`;

const PanelConfigurationBox = styled.div`
    margin-top: 5px;
    border-radius: 0.5em;
    width: 400px;
    border: 2px solid rgb(150, 150, 150);

    > div:first-child {
        padding: 20px 20px 5px;
    }

    > div:last-child {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
`;

interface ConfigurationValueProps {
    panelType?: string | undefined;
}

const PanelConfigurationValueWrapper = styled.div<ConfigurationValueProps>`
    width: 55px;
    min-width: 35px;
    max-width: 55px;
    color: ${theme.colors.grayRGBA};
    text-align: center;
    font-size: 12px;
    ${(props) =>
        props.panelType === "left" &&
        css`
            margin-right: 155px;
        `}
    ${(props) =>
        props.panelType === "right" &&
        css`
            margin-left: 155px;
        `}
    ${(props) =>
        props.panelType === "pair" &&
        css`
            margin: 0 40px;
        `}
    ${(props) =>
        props.panelType === "three-panels" &&
        css`
            margin: 0 23px;
        `}
    ${(props) =>
        props.panelType === "four-panels" &&
        css`
            margin: 0 12px;
        `}
    ${(props) =>
        props.panelType === "five-panels" &&
        css`
            margin: 0 5px;
        `}
`;

const PanelConfigurationValue = styled(SelectList)<ConfigurationValueProps>`
    border-radius: 0.5em;
    color: ${theme.colors.primary};
    width: 60px;
    min-width: 50px;
    max-width: 60px;
    margin-bottom: 5px;

    input {
        font-weight: bold;
    }
`;
PanelConfigurationValue.defaultProps = {
    noAddButton: true,
    noClearBox: true,
    noArrow: false,
    smallPadding: true,
    flexDirection: "column",
};

export const Subtitle = styled.h5`
    color: #338daa;
    font-weight: bold;
    letter-spacing: 1.3px;
    margin: 0;
`;

const GridTableWrapper = styled.div`
    margin-top: 20px;
`;

const GridTable = styled(GridTableE)`
    border: 0;
`;

const GridTableRow = styled(GridTableRowE)`
    grid-template-columns: 1.7fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "name1 name2 name3";
    grid-column-gap: 0;
    font-weight: 600;
    color: #4a4a4a;
    text-align: left;
    background-color: #ecf6f9;
    padding: 0;
    border-bottom: 1px solid ${theme.colors.grayRGBA};

    // &:hover {
    //     background-color: #c1dcec;
    // }
`;

const GridTableCell = styled(GridTableCellE)`
    border-left: 1px solid ${theme.colors.grayRGBA};
    border-top: 1px solid ${theme.colors.grayRGBA};
    color: ${theme.colors.grayRGBA};

    &:last-child {
        border-right: 1px solid ${theme.colors.grayRGBA};
    }
`;

const FirstGridTableCell = styled(GridTableCellE)`
    background: white;
    padding: 20px 10px;
    border-left: 1px solid ${theme.colors.grayRGBA};

    &:first-child {
        border-left: none;
    }
`;

const GridTitleBox = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const GridTableBody = styled(GridTableBodyE)`
    overflow: auto;
`;

const Row = styled(GridTableRowE)`
    grid-template-columns: 1.7fr 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 0;
    padding: 0;
    color: #000;

    &:last-child {
        border: 0;
    }

    &:hover {
        background-color: #f8f5f8;
    }
`;

const BasicGridTableCell = styled(GridTableCellE)`
    text-align: left;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    border: 1px solid ${theme.colors.grayRGBA};
    border-right: none;
    border-top: none;

    &:last-child {
        border-right: 1px solid ${theme.colors.grayRGBA};
    }
`;
const BasicSubGridTableCell = styled(BasicGridTableCell)`
    justify-content: center;
    color: ${theme.colors.primary};
`;

const ModalInput = styled(ValidationPopupInput)`
    padding: 10px 5px;
    height: 23px;
    width: 60px;
`;
const ValidateBox = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: center;
    color: darkred;
    background-color: #fff2f3;
    border: solid #f5c6cb 1px;
    border-radius: 0.5em;
`;

export interface RecommendationsOverridesModalOwnProps {
    active: boolean;
    draperyOrderId: number;
    isFunctional: boolean;
    panelType?: string | undefined;
    data: any; 
    selectedPanels: number;
    onRequestClose(): void;
    handleConfirm(panelJson: DraperyModule.DraperyOrderDesignPanelJson, workRoomSettings: any[], widthConfiguration:  Array<number | undefined>): void;
    editMode: boolean;
    draperyOrderDesign: DraperyModule.DraperyOrderDesign;
    newDraperyOrderFabrics: any[];
    workOrderEditMode: boolean;
    resetDraperyOrderNewFabrics(): void;
    handleSaveAll(val?: string): Promise<boolean>;
    resetAfterUpdatingEmbellishments(): void;
    isCopy: boolean;
}

export interface RecommendationsOverridesModalProps
    extends RecommendationsOverridesModalOwnProps {
    accountId: number;
    editMode: boolean;
}

interface State {
    updating: boolean;
    isNext: boolean;
    /**
     * Panel width configuration. index is the panel number
     */
    widthConfiguration: Array<number | undefined>;
    /**
     * Total width recommendation from all panels
     */
    totalWidthRecommendation: number;
    /**
     * Yardage overrides
     */
    yardageRecommendations: Array<
        WorkOrderModule.WorkOrderFabricYardage & {
            overriden?: string;
            isChecked?: boolean;
        }
    >;
    copyYardageRecommendations: Array<
        WorkOrderModule.WorkOrderFabricYardage & {
            overriden?: string;
            isChecked?: boolean;
        }
    >;
    /**
     * recommendation
     */
    // workOrderRecommendations?: WorkOrderModule.WorkOrderRecommendations;
    /**
     * overrides
     */
    WorkOrderOverrides?: WorkOrderModule.WorkOrderOverrides;
    isValidate: boolean;
    validateMessage: string;
    isOverridesValidate: boolean;
    basePrice: string;
    workroomSettings: any[];
    updatingWorkOrder: boolean;
}

const noop = () => {
    /* ignore */
};

export class RecommendationsOverridesModalComponent extends PureComponent<
    RecommendationsOverridesModalProps & DispatchProp,
    State
> {
    public constructor(
        props: RecommendationsOverridesModalProps & DispatchProp
    ) {
        super(props);
        this.state = {
            updating: false,
            isNext: !props.isFunctional,
            totalWidthRecommendation: 0,
            widthConfiguration: [],
            yardageRecommendations: [],
            copyYardageRecommendations: [],
            isValidate: false,
            validateMessage: "",
            isOverridesValidate: false,
            basePrice: "",
            workroomSettings: [],
            updatingWorkOrder: false,
        };
    }

    public async componentDidMount(): Promise<void> {
        const { dispatch, accountId } = this.props;
        try {
            const res = await dispatch(
                WorkroomSettingModule.searchWorkroomSetting(accountId)
            );
            if (res && !res.error && res.payload && res.payload.length > 0) {
                const workroomSettings = res.payload;
                this.setState({ workroomSettings });
            } else {
                console.error("There is no information about the settings.");
            }
        } catch (error) {
            console.error("Error executing the dispatch function:", error);
        }

        // await this.getWorkOrderRecommendations();
    }

    public async componentDidUpdate(
        prevProps: RecommendationsOverridesModalProps,
        prevState: any,
    ): Promise<void> {
        if (prevProps.isFunctional !== this.props.isFunctional) {
            this.setState({
                isNext: !this.props.isFunctional,
            });
        }

        if (prevProps.panelType !== this.props.panelType) {
            this.setState({
                widthConfiguration: this.calculateInitialPanelWidths(
                    this.state.totalWidthRecommendation
                ),
            });
        }
        if (this.props.draperyOrderId === 0 && prevProps.draperyOrderId !== 0) {
            this.setState({
                widthConfiguration: [undefined],
            });
        }
        if (this.props.workOrderEditMode && prevProps.workOrderEditMode !== this.props.workOrderEditMode) {
            this.setState({
                widthConfiguration: [undefined],
            });        
        }
        
        if (this.state.updatingWorkOrder === true) {
            await this.save()
        }
    }
    componentWillReceiveProps(nextProps: Readonly<RecommendationsOverridesModalProps & DispatchProp<AnyAction>>, nextContext: any): void {
        if(nextProps.isCopy){
            this.setState((state) => ({...state, yardageRecommendations: this.state.yardageRecommendations.map((item) => ({ ...item, overriden: ''})), 
            copyYardageRecommendations: this.state.copyYardageRecommendations.map((item) => ({ ...item, overriden: ''}))}))
        }
    }
    public render(): JSX.Element {
        return this.renderModal();
    }

    private renderModal = () => {
        const { active } = this.props;
        return (
            <>
                <PageDimmer uiActive={active} />
                <Modal
                    uiActive={active}
                    uiOnRequestClose={noop}
                    uiOnOpen={this.loadRecommendations}
                >
                    <ModalHeaderRecommendationsOverrides>
                        <div>
                            <span className="modal-title">Recommendations</span>
                        </div>
                    </ModalHeaderRecommendationsOverrides>
                    {this.state.isNext
                        ? this.renderYardagesSelection()
                        : this.renderWidthSelection()}
                </Modal>
            </>
        );
    };

    private renderWidthSelection = () => {
        return (
            <>
                <ModalContent>
                    <ModalSpan>
                        Please choose number of widths for each panel.
                    </ModalSpan>
                    <PanelConfigurationWrapper>
                        <PanelConfigurationSubWrapper>
                            <ModalSpan>
                                {this.props.panelType === "center" &&
                                    "SINGLE CENTER"}
                                {this.props.panelType === "left" && "LEFT"}
                                {this.props.panelType === "right" && "RIGHT"}
                                {this.props.panelType === "pair" && "PAIR"}
                                {this.props.panelType === "three-panels" &&
                                    "3 PANELS"}
                                {this.props.panelType === "four-panels" &&
                                    "4 PANELS"}
                                {this.props.panelType === "five-panels" &&
                                    "5 PANELS"}
                            </ModalSpan>
                            <PanelConfigurationBox>
                                <div>
                                    {this.handlePanelConfigurationImage()}
                                </div>
                                <div>{this.generateConfigurationValue()}</div>
                            </PanelConfigurationBox>
                        </PanelConfigurationSubWrapper>
                    </PanelConfigurationWrapper>
                    {this.state.isValidate && (
                        <ValidateBox>{this.state.validateMessage}</ValidateBox>
                    )}
                    <TwoCounterWrapper>
                        <TwoCounterTitleBox>TOTAL WIDTHS</TwoCounterTitleBox>
                        <TwoCounterLabelValueBox>
                            <TwoCounterLabel>Recommended:</TwoCounterLabel>
                            <TwoCounterValue>
                                {this.state.totalWidthRecommendation}
                            </TwoCounterValue>
                            <TwoCounterLabel>Added:</TwoCounterLabel>
                            <TwoCounterValue>
                                {this.state.widthConfiguration.length ? (
                                    (this.total() || 0).toFixed(2) || <>&nbsp;</>
                                        ) : (
                                    <>&nbsp;</>
                                )}
                            </TwoCounterValue>
                        </TwoCounterLabelValueBox>
                    </TwoCounterWrapper>
                </ModalContent>
                <ModalActions>
                    <CancelButton
                        uiColor="secondary"
                        uiDisabled={this.state.updating}
                        onClick={this.handleClose}
                    >
                        Cancel
                    </CancelButton>
                    <Button
                        form="cardform"
                        uiColor="primary"
                        onClick={this.handleNext}
                    >
                        Next
                    </Button>
                </ModalActions>
            </>
        );
    };

    private renderYardagesSelection = () => {
        return (
            <>
                <ModalContent>
                    <ModalSpan>
                        Confirm or Override the yardages for the selected
                        fabrics and embellishments.
                    </ModalSpan>
                    <GridTableWrapper>
                        <GridTable>
                            <GridTableRow>
                                <FirstGridTableCell uiGridArea="name1" />
                                <GridTableCell uiGridArea="name2">
                                    <GridTitleBox className="text-sm md:text-md">
                                        <Subtitle className="text-sm md:text-md">
                                            RECOMMENDED
                                        </Subtitle>
                                        yards
                                    </GridTitleBox>
                                </GridTableCell>
                                <GridTableCell uiGridArea="name3">
                                    <GridTitleBox className="text-sm md:text-md">
                                        <Subtitle className="text-sm md:text-md">
                                            OVERRIDE
                                        </Subtitle>
                                        yards
                                    </GridTitleBox>
                                </GridTableCell>
                            </GridTableRow>
                            <GridTableBody>
                                {this.state.yardageRecommendations.map((y) =>
                                    this.renderYardageRecommendationRow(
                                        y.id,
                                        y.name,
                                        y.yardage,
                                        y.overriden,
                                        y.isChecked
                                    )
                                )}
                            </GridTableBody>
                        </GridTable>
                    </GridTableWrapper>
                    {this.state.isOverridesValidate && (
                        <ValidateBox>
                            Overriding yardages must be greater than zero
                        </ValidateBox>
                    )}
                </ModalContent>
                <ModalActions>
                    <CancelButton
                        uiColor="secondary"
                        uiDisabled={this.state.updating}
                        onClick={this.handleClose}
                    >
                        Cancel
                    </CancelButton>
                    <Button
                        type="submit"
                        form="cardform"
                        uiColor="primary"
                        uiDisabled={this.state.updating}
                        onClick={this.save}
                    >
                        Confirm
                    </Button>
                </ModalActions>
            </>
        );
    };

    total = (): number | undefined => {
        const totalNum = this.state.widthConfiguration.reduce((all, v) => {
            if (v === undefined) {
                v = 0;
                return all! + v!;
            } else {
                return all! + v!;
            }
        });
        return totalNum;
    };

    private handleNext = async () => {
        const isValidate = this.handleValidateRecommendations();
        if (isValidate) {
            return;
        } else {
            const { widthConfiguration } = this.state;
            this.setState({ updating: true });
            await this.props.dispatch(
                WorkOrderModule.saveWorkOrderOverrides(
                    this.props.draperyOrderId,
                    this.props.accountId,
                    {
                        panelJson: {
                            pannel1Width: widthConfiguration[0],
                            pannel2Width: widthConfiguration[1],
                            pannel3Width: widthConfiguration[2],
                            pannel4Width: widthConfiguration[3],
                            pannel5Width: widthConfiguration[4],
                        },
                        yardages: undefined,
                    },
                    undefined
                )
            );

            const payload = {
                draperyOrderId: this.props.draperyOrderId,
                type: "yardage",
                totalWidth: 0,
                yardages: [],
                newDraperyDesign: this.props.workOrderEditMode ? this.props.draperyOrderDesign : null,
                newDraperyOrderFabrics: this.props.workOrderEditMode ? this.props.newDraperyOrderFabrics : null
            };

            const res = await getWorkOrderRecommendations(payload);

            // if (!res.error && res.payload.yardages) {
            this.setState({
                isNext: true,
                yardageRecommendations: res.yardages.map((item: any) =>
                    item.yardage === -1 ? { ...item, isChecked: true } : item
                ),
            });
            // }
            this.setState({ updating: false });
        }
    };

    private handleValidateRecommendations = (): boolean => {
        if (this.state.widthConfiguration.includes(undefined)) {
            this.setState({
                isValidate: true,
                validateMessage: "Please select the number widths per panel.",
            });
            return true;
        }

        const widths = [...new Set(this.state.widthConfiguration)];
        if (widths && widths.length > 2 && this.props.data.mainFabric.directionOfUse === 2) {
            this.setState({
                isValidate: true,
                validateMessage:
                    "A maximum of 2 differing panel widths are allowed when the Main Fabric is railroaded.",
            });
            return true;
        } else {
            this.setState({ isValidate: false, validateMessage: "" });
            return false;
        }
    };

    private renderYardageRecommendationRow = (
        id: number,
        name: string,
        recommended: number,
        overriden?: string,
        isChecked?: boolean
    ) => {
        const showTooltip =
            name.startsWith("BANDING FABRIC") ||
            name.startsWith("FLANGE FABRIC") ||
            name.startsWith("TRIM FABRIC");
        const showWarning =
            name.startsWith("WELT CORDING FABRIC") &&
            (!overriden || +overriden < 54);

        return (
            <Row key={name}>
                <BasicGridTableCell>
                    {name}
                    {showTooltip && (
                        <TooltipPopup
                            text={
                                <TooltipText>
                                    Yardage calculations for embellishments made
                                    from fabric are calculated to be cut the
                                    same direction they will be applied. For
                                    example, banding to be applied horizontally,
                                    will be calculated as a cut across the width
                                    of fabric.
                                </TooltipText>
                            }
                        />
                    )}
                </BasicGridTableCell>
                <BasicSubGridTableCell>
                    {recommended === -1 ? "CALC MANUALLY" : recommended}
                    {showWarning && (
                        <TooltipPopup
                            errorIcon
                            text={
                                <TooltipText>
                                    QUIPA calculations account for welt cut on
                                    the bias from a 54" wide fabric or larger.
                                    If your fabric is less than 54", then manual
                                    calculation is needed.
                                </TooltipText>
                            }
                        />
                    )}
                </BasicSubGridTableCell>
                <BasicSubGridTableCell>
                    <Checkbox
                        id={`enable-${id}`}
                        name={id ? id.toString() : name}
                        checked={!!isChecked}
                        onChange={this.handleYardageOverrideCheckbox}
                    />
                    <ModalInput
                        id={id.toString()}
                        type="number"
                        disabled={!isChecked}
                        autoComplete="off"
                        name={id ? id.toString() : name}
                        onChange={this.handleYardageOverrideInput}
                        value={overriden}
                    />
                </BasicSubGridTableCell>
            </Row>
        );
    };

    private handleValidateOverrides = (): boolean => {
        const targetItem = this.state.yardageRecommendations.find(
            (item) =>
                !!item.isChecked && (!item.overriden || +item.overriden <= 0)
        );
        if (targetItem !== undefined) {
            this.setState({ isOverridesValidate: true });
            return true;
        } else {
            this.setState({ isOverridesValidate: false });
            return false;
        }
    };

    private save = async () => {
        const panelJson = {
            pannel1Width: this.state.widthConfiguration[0],
            pannel2Width: this.state.widthConfiguration[1],
            pannel3Width: this.state.widthConfiguration[2],
            pannel4Width: this.state.widthConfiguration[3],
            pannel5Width: this.state.widthConfiguration[4],
        };
        const { yardageRecommendations, copyYardageRecommendations } = this.state;
        if (this.handleValidateOverrides()) {
            return;
        } else {
            const {dispatch, accountId, draperyOrderId, isFunctional} =
                this.props;
            if (!draperyOrderId) {
                return;
            }
            this.setState({updating: true});

            if (this.props.workOrderEditMode && !this.state.updatingWorkOrder) {
                if (!(await this.props.handleSaveAll('no_message'))) {
                    return
                }

                const newArrayPayloadYardages = yardageRecommendations && yardageRecommendations.length > 0 && yardageRecommendations.map(item => {
                    return {
                        ...item,
                        yardage: item.isChecked ? parseFloat(item.overriden!) : item.yardage
                    }
                })

                this.setState({
                    copyYardageRecommendations: newArrayPayloadYardages || []
                })

                await this.loadRecommendations()
                this.setState(prev => {
                    return {
                        ...prev,
                        updatingWorkOrder: true
                    }
                })

                return
            }

            copyYardageRecommendations.forEach(i => {
                const foundMatch = yardageRecommendations.find(ii => ii.name === i.name)
                if (foundMatch) {
                    i.id = foundMatch.id
                }
            })

            let payloadForYardage = []
            if (this.props.workOrderEditMode) {
                payloadForYardage = copyYardageRecommendations
            } else {
                payloadForYardage = yardageRecommendations
            }

            const res = await dispatch(
                WorkOrderModule.saveWorkOrderOverrides(
                    draperyOrderId,
                    accountId,
                    {
                        yardages: payloadForYardage.map((y) => ({
                            id: y.id,
                            name: y.name,
                            yardage: y.isChecked
                                ? parseFloat(y.overriden!)
                                : y.yardage,
                        })),
                        panelJson: undefined
                    },
                )
            );

            this.setState({ updating: false, updatingWorkOrder: false });
            if (!res.error) {
                this.props.resetAfterUpdatingEmbellishments()
                await this.props.handleConfirm(panelJson, this.state.workroomSettings, this.state.widthConfiguration);

                this.setState({isNext: !isFunctional});
                // await this.props.saveDraperyOrderPricing();
                
            } else {
                // todo: handle error
            }
            }
    };

    private loadRecommendations = async () => {
        if (!this.state.isNext) {
            const payload = {
                draperyOrderId: this.props.draperyOrderId,
                type: "panel",
                totalWidth: 0,
                yardages: [],
                newDraperyDesign: this.props.workOrderEditMode ? this.props.draperyOrderDesign : null,
                newDraperyOrderFabrics: this.props.workOrderEditMode ? this.props.newDraperyOrderFabrics : null
            };

            const res = await getWorkOrderRecommendations(payload);

            // if (!res.error && res.payload.totalWidth) {
            this.setState({
                totalWidthRecommendation: res.totalWidth,
                widthConfiguration: this.calculateInitialPanelWidths(
                    res.totalWidth
                ),
            });
            // }
        } else {
            const payload = {
                draperyOrderId: this.props.draperyOrderId,
                type: "yardage",
                totalWidth: 0,
                yardages: [],
                newDraperyDesign: this.props.workOrderEditMode ? this.props.draperyOrderDesign : null,
                newDraperyOrderFabrics: this.props.workOrderEditMode ? this.props.newDraperyOrderFabrics : null
            };

            const res = await getWorkOrderRecommendations({...payload, editMode: this.props.editMode});

            // const res = await this.props.dispatch(
            //     WorkOrderModule.getWorkOrderRecommendation(
            //         this.props.draperyOrderId,
            //         "yardage"
            //     )
            // );
            // if (!res.error && res.payload.yardages) {
            this.setState(prev => {
                return {
                    ...prev,
                    yardageRecommendations: res.yardages.map((item: any) =>
                        item.yardage === -1 ? { ...item, isChecked: true, overriden: "" } : {...item, overriden: ""}
                    ),
                }
            });
            // }
        }
    };

    private handleYardageOverrideCheckbox = (
        e: ChangeEvent<HTMLInputElement>
    ) => {
        const val = e.currentTarget.checked;
        const name = e.currentTarget.name;
        const confIdx = this.state.yardageRecommendations.findIndex(
            (y) => {
                if (!y.id) {
                    return y.name === name
                } else {
                    return y.id === parseInt(name, 10)
                }
            }
        );
        const conf = this.state.yardageRecommendations[confIdx];
        if (!conf) {
            return;
        }
        if (conf.yardage === -1) {
            return;
        }
        const confUpdate = { ...conf, isChecked: val };
        if (val && !("overriden" in confUpdate)) {
            confUpdate.overriden = confUpdate.yardage.toString();
        } else {
            confUpdate.overriden = "";
        }

        this.setState({
            yardageRecommendations: [
                ...this.state.yardageRecommendations.slice(0, confIdx),
                { ...confUpdate, overriden: "" },
                ...this.state.yardageRecommendations.slice(confIdx + 1),
            ],
            copyYardageRecommendations: [
                ...this.state.yardageRecommendations.slice(0, confIdx),
                { ...confUpdate, overriden: "" },
                ...this.state.yardageRecommendations.slice(confIdx + 1),
            ],
        });
    };

    private handleYardageOverrideInput = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        const id = e.currentTarget.id;
        const name = e.currentTarget.name

        const confIdx = this.state.yardageRecommendations.findIndex(
            (y) => {
                if (!y.id) {
                    return y.name === name
                } else {
                    return y.id === parseInt(id, 10)
                }
            }        );
        const conf = this.state.yardageRecommendations[confIdx];
        if (!conf) {
            return;
        }
        if (this.state.yardageRecommendations.length > 1) {
            this.setState({
                yardageRecommendations: [
                    ...this.state.yardageRecommendations.slice(0, confIdx),
                    { ...conf, overriden: val },
                    ...this.state.yardageRecommendations.slice(confIdx + 1),
                ],
                copyYardageRecommendations: [
                    ...this.state.yardageRecommendations.slice(0, confIdx),
                    { ...conf, overriden: val },
                    ...this.state.yardageRecommendations.slice(confIdx + 1),
                ],
            });
        } else {
            this.setState({
                yardageRecommendations: [{ ...conf, overriden: val }],
                copyYardageRecommendations: [{ ...conf, overriden: val }],
            });
        }
    };

    private handleClose = () => {
        if (this.state.updating) {
            return;
        }
        this.setState({
            isNext: !this.props.isFunctional,
            totalWidthRecommendation: 0,
            widthConfiguration: [],
            yardageRecommendations: [],
            isValidate: false,
        });
        this.props.resetDraperyOrderNewFabrics()
        this.props.onRequestClose();
    };

    private handlePanelConfigurationImage = () => {
        switch (this.props.panelType) {
            case "center": {
                return <DraperyConfigurationSingleCenterPanel />;
            }
            case "left": {
                return <DraperyConfigurationSingleLeftPanel />;
            }
            case "right": {
                return <DraperyConfigurationSingleRightPanel />;
            }
            case "pair": {
                return <DraperyConfigurationPair />;
            }
            case "three-panels": {
                return <DraperyConfiguration3Panels />;
            }
            case "four-panels": {
                return <DraperyConfiguration4Panels />;
            }
            case "five-panels": {
                return <DraperyConfiguration5Panels />;
            }
        }
    };

    private calculateInitialPanelWidths(
        totalWidth: number
    ): Array<number | undefined> {
        let configurationValueQuantity = 0;
        const valueQuantityArray: Array<number | undefined> = [];
        switch (this.props.panelType) {
            case "center": {
                configurationValueQuantity = 1;
                break;
            }
            case "left": {
                configurationValueQuantity = 1;
                break;
            }
            case "right": {
                configurationValueQuantity = 1;
                break;
            }
            case "pair": {
                configurationValueQuantity = 2;
                break;
            }
            case "three-panels": {
                configurationValueQuantity = 3;
                break;
            }
            case "four-panels": {
                configurationValueQuantity = 4;
                break;
            }
            case "five-panels": {
                configurationValueQuantity = 5;
                break;
            }
        }
        for (let i = 0; i < configurationValueQuantity; i++) {
            valueQuantityArray.push(undefined);
        }
        return valueQuantityArray;
    }

    private generateConfigurationValue = () => {
        let inputCount = 10; //random number, no significance
 
        return this.state.widthConfiguration.map((item, idx) => {
            inputCount++;
            return (
                <PanelConfigurationValueWrapper
                    key={`recom-input-${inputCount}`} //workaround for sonar lint when using idx as key
                    panelType={this.props.panelType}
                >
                    <PanelConfigurationValue
                        selectList={[
                            "0",
                            "0.5",
                            "1",
                            "1.5",
                            "2",
                            "2.5",
                            "3",
                            "3.5",
                            "4",
                            "4.5",
                            "5",
                            "5.5",
                            "6",
                            "6.5",
                            "7",
                            "7.5",
                            "8",
                        ]}
                        handleClickSelect={(value) => this.handleConfigurationWidthChange(value, idx.toString())}
                        valueSelect={item === undefined ? "" : item.toString()}
                        flexDirection="column"
                        name={idx.toString()}
                        noClearBox
                        noArrow
                        editable
                        type="number"
                        uiBorderStyle={`solid 2px ${theme.colors.grayRGBA}`}
                    />
                    Width(s)
                </PanelConfigurationValueWrapper>
            );
        });
    };

    private handleConfigurationWidthChange = (value: string | number, name: string) => {
        const idx = parseInt(name, 10);
        let newWidth: number | undefined = undefined;
    
        const invalidCharactersRegex = /[e\-\+]/;
        const validValueRegex = /^(0|[1-9]\d{0,1})(\.\d{0,2})?$/;
    

        const stringValue = typeof value === 'number' ? value.toString() : value;
    
        if (!invalidCharactersRegex.test(stringValue) && validValueRegex.test(stringValue)) {
            let processedValue = stringValue.replace(/[^\d.]/g, '');    
            processedValue = processedValue.replace(/\.{2,}/g, '.');
    
            const firstDotIndex = processedValue.indexOf('.');
            if (firstDotIndex !== -1) {
                processedValue = `${processedValue.slice(0, firstDotIndex + 1)}${processedValue.slice(firstDotIndex + 1).replace(/\./g, '')}`;
            }
    
            const decimalParts = processedValue.split('.');
            if (decimalParts.length > 1) {
                processedValue = `${decimalParts[0]}.${decimalParts[1].slice(0, 2)}`;
            }
    
            newWidth = parseFloat(processedValue);
            if (!isNaN(newWidth)) {
                newWidth = Math.min(Math.max(newWidth, 0), 99.99);
                newWidth = parseFloat(newWidth.toFixed(2));
            }
        }
    
        this.setState({
            widthConfiguration: [
                ...this.state.widthConfiguration.slice(0, idx),
                newWidth,
                ...this.state.widthConfiguration.slice(idx + 1),
            ],
        });
    };

    // private roundWidth(width: number): number {
    //     const wholeNum = Math.floor(width);
    //     return wholeNum === width ? width : width <= wholeNum + 0.5 ? wholeNum + 0.5 : Math.ceil(width);
    // }
}

function mapStateToProps(
    state: RootState,
    ownProps: RecommendationsOverridesModalOwnProps
): RecommendationsOverridesModalProps {
    return {
        ...ownProps,
        accountId: state.account.id,
        editMode: ownProps.editMode,
    };
}

export const RecommendationsOverridesModal = connect(mapStateToProps)(
    RecommendationsOverridesModalComponent
);
